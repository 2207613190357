import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import PuxVideo, { PuxVideoType } from '../PuxVideo/PuxVideo'

export interface WidgetPuxVideoContainerType extends WidgetBase {
  contentType: 'PuxVideoContainer'
  videoContainerList: {
    contentItems: [
      {
        puxVideo: PuxVideoType
      }
    ]
  }
}

const WidgetPuxVideoContainer: FunctionComponent<WidgetPuxVideoContainerType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
  >
    {props.videoContainerList?.contentItems.map((item, idx) => {
      if (item?.puxVideo) {
        return <PuxVideo {...item.puxVideo} key={idx} />
      }

      return null
    })}
  </WidgetContainer>
)
export default WidgetPuxVideoContainer
